<template>
  <div class="listing--form-container">
    <!--    seccccccccccccccccccc 2-->
    <div class="form--group" :class="{ 'has-error': validation.hasError('title') }">
      <div class="left--col">
        <label for="title-field">
          <span>{{ $t('addListing.listingTitle') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input
          :placeholder="$t('addListing.placeholder.title')"
          class="basic--input"
          id="title-field"
          name="title"
          type="text"
          v-model="title"
        />
        <span class="val-error" v-if="validation.hasError('title')">{{
          validation.firstError('title')
        }}</span>
      </div>
    </div>

    <div class="form--group" :class="{ 'has-error': validation.hasError('description') }">
      <div class="left--col">
        <label for="description-field">
          <span>{{ $t('general.description') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <textarea
          v-model="description"
          :placeholder="$t('addListing.placeholder.description')"
          id="description-field"
          name="description"
          class="basic--input"
          type="text"
          rows="4"
        />
        <span class="val-error" v-if="validation.hasError('description')">{{
          validation.firstError('description')
        }}</span>
      </div>
    </div>

    <photo-section ref="photoSection" />

    <div class="form--group" :class="{ 'has-error': validation.hasError('price') }">
      <div class="left--col">
        <label for="price-field">
          <span>{{ $t('addListing.price') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <div class="input--group">
          <div class="append--left">Rp</div>
          <cleave
            id="price-field"
            class="basic--input price--input"
            type="text"
            name="price"
            v-model="price"
            :options="cleaveOption"
            ref="price"
          />
        </div>
        <span class="val-error" v-if="validation.hasError('price')">{{
          validation.firstError('price')
        }}</span>
      </div>
    </div>

    <unit-detail-section ref="unitDetailSection" />
    <div
      class="form--group"
      :class="{ 'has-error': validation.hasError('hoek') }"
      v-show="hoekRule.allowed"
    >
      <div class="left--col">
        <label for="select-furnish-status">
          <span>{{ $t('addListing.hoek') }}</span>
          <span class="required" v-show="hoekRule.required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          name="certificate"
          id="select-hoek-status"
          v-model="hoek"
          :options="[1, 0]"
          :searchable="false"
          :allowEmpty="true"
          :close-on-select="true"
          :class="validation.hasError('hoek') ? 'is-invalid' : ''"
          :placeholder="$t('addListing.placeholder.hoek')"
        >
          <template slot="option" slot-scope="{ option }">
            <span>{{ option === 1 ? 'Ya' : 'Tidak' }}</span>
          </template>
          <template slot="singleLabel" slot-scope="{ option }">
            {{ option === 1 ? 'Ya' : 'Tidak' }}
          </template>
        </multiselect>

        <span class="val-error" v-if="validation.hasError('hoek')">{{
          validation.firstError('hoek')
        }}</span>
      </div>
    </div>

    <div
      class="form--group"
      :class="{ 'has-error': validation.hasError('selectedFurnishingStatus') }"
      v-show="furnishingStatusRule.allowed"
    >
      <div class="left--col">
        <label for="select-furnish-status">
          <span>{{ $t('addListing.furnishStatus') }}</span>
          <span class="required" v-show="furnishingStatusRule.required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          name="certificate"
          id="select-furnish-status"
          v-model="selectedFurnishingStatus"
          track-by="id"
          label="name"
          :options="furnishingStatusList"
          :searchable="true"
          :allowEmpty="false"
          :close-on-select="true"
          :show-labels="true"
          :class="validation.hasError('selectedFurnishingStatus') ? 'is-invalid' : ''"
          :placeholder="$t('addListing.placeholder.furnishStatus')"
          select-label=""
          deselect-label=""
        >
        </multiselect>

        <span class="val-error" v-if="validation.hasError('selectedFurnishingStatus')">{{
          validation.firstError('selectedFurnishingStatus')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('selectedCertificate') }">
      <div class="left--col">
        <label for="select-certificate">
          <span>{{ $t('addListing.certificate') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          name="certificate"
          id="select-certificate"
          v-model="selectedCertificate"
          track-by="id"
          label="name"
          :allowEmpty="false"
          :options="certificateList"
          :searchable="true"
          :close-on-select="true"
          :show-labels="true"
          :class="validation.hasError('selectedCertificate') ? 'is-invalid' : ''"
          :placeholder="$t('addListing.placeholder.certificate')"
          select-label=""
          deselect-label=""
        >
        </multiselect>
        <span class="val-error" v-if="validation.hasError('selectedCertificate')">{{
          validation.firstError('selectedCertificate')
        }}</span>
      </div>
    </div>
    <div
      v-show="false"
      class="form--group"
      :class="{ 'has-error': validation.hasError('youtubeUrl') }"
    >
      <div class="left--col">
        <label for="youtube-field">
          <span>{{ $t('addListing.youtubeUrl') }}</span>
        </label>
      </div>
      <div class="right--col">
        <input
          :placeholder="$t('addListing.placeholder.youtubeUrl')"
          class="basic--input"
          id="youtube-field"
          name="category_name"
          type="text"
          v-model="youtubeUrl"
        />
        <span class="val-error" v-if="validation.hasError('youtubeUrl')">{{
          validation.firstError('youtubeUrl')
        }}</span>
      </div>
    </div>
    <video-section ref="videoSection"></video-section>
    <facility-section ref="facilitySection" />
    <div class="bottom--container">
      <button class="btn btn-primary--outline bottom--button" @click="back">
        {{ $t('addListing.btn.back') }}
      </button>
      <button
        v-show="form === 'add'"
        class="btn btn-primary--outline bottom--button"
        @click="saveDraft"
      >
        {{ $t('addListing.btn.saveToDraft') }}
      </button>
      <button v-show="form !== 'add'" class="btn btn-primary--outline bottom--button" @click="save">
        {{ $t('addListing.btn.save') }}
      </button>
      <button v-show="status === 'DRAFT'" class="btn btn-primary bottom--button" @click="activate">
        {{ `${$t('listing.modal.activate.btn')} (1 ${$t('general.coin')})` }}
      </button>
      <button
        v-show="status === 'ACTIVE' || status === 'EXPIRED' || status === 'NOT_ACTIVE'"
        class="btn btn-primary bottom--button"
        @click="repost"
      >
        {{ `${$t('general.btn.repost')} (1 ${$t('general.coin')})` }}
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { Validator } from 'simple-vue-validator';
import { mapGetters, mapState } from 'vuex';
import Cleave from 'vue-cleave-component';
import HelperMixin from '@/mixins/helpers';

require('vue-multiselect/dist/vue-multiselect.min.css');
const UnitDetailSection = () => import('@/components/listing-form/sect2/unit-detail-section.vue');
const VideoSection = () => import('@/components/listing-form/sect2/video/video-section.vue');
const FacilitySection = () =>
  import('@/components/listing-form/sect2/facility/facilities-section.vue');
const PhotoSection = () => import('@/components/listing-form/sect2/photo/photo-section.vue');

import CheckYoutubeUrlClass from '@/validations/checkYoutubeUrl';

export default {
  mixins: [HelperMixin],
  components: {
    Multiselect,
    UnitDetailSection,
    VideoSection,
    FacilitySection,
    PhotoSection,
    Cleave,
  },
  data() {
    return {
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.listingForm.status,
      form: (state) => state.listingForm.form,
      propertyTypeList: (state) => state.listingForm.sect1.location.propertyTypeList,
      furnishingStatusList: (state) => state.listingForm.sect2.detail.furnishingStatusList,
      certificateList: (state) => state.listingForm.sect2.detail.certificateList,
    }),

    ...mapGetters({
      hoekRule: 'listingForm/sect2/detail/hoekRule',
      furnishingStatusRule: 'listingForm/sect2/detail/furnishingStatusRule',
    }),
    loading: {
      get() {
        return this.$store.state.listingForm.isLoading;
      },
      set(val) {
        this.$store.commit('listingForm/SET_LOADING', val);
      },
    },
    selectedFurnishingStatus: {
      get() {
        return this.$store.state.listingForm.sect2.detail.selectedFurnishingStatus;
      },
      set(val) {
        this.$store.commit('listingForm/sect2/detail/SET_SELECTED_FURNISHING_STATUS', val);
      },
    },
    hoek: {
      get() {
        return this.$store.state.listingForm.sect2.detail.hoek;
      },
      set(val) {
        this.$store.commit('listingForm/sect2/detail/SET_HOEK', val);
      },
    },
    selectedCertificate: {
      get() {
        return this.$store.state.listingForm.sect2.detail.selectedCertificate;
      },
      set(val) {
        this.$store.commit('listingForm/sect2/detail/SET_SELECTED_CERTIFICATE', val);
      },
    },
    title: {
      get() {
        return this.$store.state.listingForm.sect2.detail.title;
      },
      set(val) {
        this.$store.commit('listingForm/sect2/detail/SET_TITLE', this.cleanInput(val));
      },
    },
    youtubeUrl: {
      get() {
        return this.$store.state.listingForm.sect2.detail.youtubeUrl;
      },
      set(val) {
        this.$store.commit('listingForm/sect2/detail/SET_YOUTUBE_URL', this.cleanInput(val));
      },
    },
    description: {
      get() {
        return this.$store.state.listingForm.sect2.detail.description;
      },
      set(val) {
        this.$store.commit('listingForm/sect2/detail/SET_DESCRIPTION', this.cleanInput(val));
      },
    },
    price: {
      get() {
        return this.$store.state.listingForm.sect2.detail.price;
      },
      set(val) {
        this.$store.commit('listingForm/sect2/detail/SET_PRICE', val);
      },
    },
    currentStep: {
      get() {
        return this.$store.state.listingForm.formCurrentStep;
      },
      set(value) {
        this.$store.commit('listingForm/SET_FORM_CURRENT_STEP', value);
      },
    },
  },
  validators: {
    youtubeUrl(value) {
      const checkYoutubeUrl = new CheckYoutubeUrlClass(value, this.$i18n);
      return Validator.value(value).custom(checkYoutubeUrl.isValidFunc);
    },
    selectedCertificate(value) {
      return Validator.value(value).required(this.$i18n.t('errors.certificate.required'));
    },
    selectedFurnishingStatus(value) {
      if (!this.furnishingStatusRule.allowed || !this.furnishingStatusRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.furnishingStatus.required'));
    },
    hoek(value) {
      if (!this.hoekRule.allowed || !this.hoekRule.required) return null;
      return Validator.value(value).required(this.$i18n.t('errors.hoek.required'));
    },
    title(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.listingTitle.required'))
        .lengthBetween(
          5,
          70,
          this.$i18n.t('errors.listingTitle.between', {
            minChar: 5,
            maxChar: 70,
          }),
        );
    },
    description(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.description.required'))
        .lengthBetween(
          10,
          2000,
          this.$i18n.t('errors.description.between', {
            minChar: 10,
            maxChar: 2000,
          }),
        );
    },
    price(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.price.required'))
        .between(
          1000,
          999999999999,
          this.$i18n.t('errors.price.between', {
            minChar: 1000,
            maxChar: 999999999999,
          }),
        );
    },
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.unitDetailSection.validate());
      validationArray.push(this.$refs.videoSection.validate());
      validationArray.push(this.$refs.facilitySection.validate());
      validationArray.push(this.$refs.photoSection.validate());

      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            console.log('qqqqqq', result);
            return !result;
          }).length === 0
        );
      });
    },

    async back() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          if (this.form === 'edit') {
            await this.postData();
          }
          this.scrollToTop();
          this.currentStep -= 1;
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async postData(action = 'save') {
      try {
        this.loading = true;
        await this.$store.dispatch('listingForm/postData', action);
      } finally {
        this.loading = false;
      }
    },
    async showSuccessSwal(action) {
      let msg = '';
      switch (action) {
        case 'activate':
          msg = 'Listing berhasil diaktivasi.';
          break;
        case 'repost':
          msg = 'Listing berhasil direpost.';
          break;
        case 'draft':
          msg = 'Listing berhasil disimpan ke Draft.';
          break;
        case 'save':
          msg = 'Listing berhasil disimpan.';
          break;
      }
      await this.$swal(this.$t('general.success'), msg, 'success');
    },
    async save() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          await this.postData();
          this.showSuccessSwal('save');
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async saveDraft() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          await this.postData();
          await this.showSuccessSwal('draft');
          this.$router.replace('/');
          // eslint-disable-next-line no-empty
        } catch (e) {}
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async activate() {
      const isValid = await this.validate();
      if (isValid) {
        this.$modal.show('activate-listing-modal', { submitAction: this.activateSubmit });
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async activateSubmit() {
      try {
        await this.postData('activate');
        await this.showSuccessSwal('activate');
        this.$router.replace('/');

        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async repost() {
      const isValid = await this.validate();
      if (isValid) {
        this.$modal.show('repost-listing-modal', { submitAction: this.repostSubmit });
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    async repostSubmit() {
      try {
        await this.postData('repost');
        this.showSuccessSwal('repost');
        this.$router.replace('/');

        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
